import CardsList from "../../components/CardsList/CardsList";
function Main() {
  return (
    <div className="main page">
      <CardsList />
    </div>
  );
}

export default Main;
